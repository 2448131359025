<template>
  <div
    :class="$style.root"
  >
    <p
      v-if="item?.title"
      :class="$style.title"
    >
      {{ item.title }}
    </p>

    <div
      v-if="item?.text"
      :class="$style.text"
      v-html="item.text"
    />

    <button
      :class="$style.close"
      @click="onClose"
    >
      <svg
        :class="$style.icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.46446 15.5357L15.5355 8.4646"
          stroke="currentColor"
          stroke-width="1.6"
          stroke-linecap="round"
        />
        <path
          d="M8.46446 8.46433L15.5355 15.5354"
          stroke="currentColor"
          stroke-width="1.6"
          stroke-linecap="round"
        />
      </svg>
    </button>
  </div>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits({
  click: [Event],
});

const onClose = (e) => {
  props.item?.data?.onClose?.(e);

  emit('click');
};
</script>

<style lang="scss" scoped>
.custom-notification {
  &.success {
    --bg-color: #2fbb68;
  }

  &.info {
    --bg-color: #0855c4;
  }

  &.error {
    --bg-color: #a1adcd;
  }
}
</style>

<style lang="scss" module>
.root {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 3px 10px rgba(9, 15, 30, 12%);
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  overflow: hidden;
  padding: 14px 12px 14px 16px;
  position: relative;

  --bg-color: #0855c4;

  & > * + * {
    margin-top: 2px;
  }

  &::before {
    background-color: var(--bg-color);
    border-radius: 0 4px 4px 0;
    content: '';
    height: calc(100% - 24px);
    left: 0;
    padding: 12px 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
  }
}

.title {
  color: #282828;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: 85%;
}

.text {
  color: #707a97;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  width: 85%;
}

.close {
  border: none;
  background-color: #f0f2f5;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  padding: 4px;
  position: absolute;
  right: 12px;
  top: 12px;
}

.icon {
  color: #707a97;
  height: 16px;
  width: 16px;
}
</style>
