<template>
  <div v-if="isLoading">
    Loading...
  </div>
  <div v-else-if="appLoadingState === LoadingStatesEnum.ERROR">
    Application loading failed
  </div>
  <template v-else-if="appLoadingState === LoadingStatesEnum.SUCCESS">
    <Layout>
      <TechnicalWorks v-if="isTechnicalWorks" />

      <template v-else>
        <Login v-if="!isAuthorized" />
        <RouterView v-else />
      </template>
    </Layout>
  </template>

  <Modal />

  <CustomNotifications />
</template>

<script setup>
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import CustomNotifications from '@/components/customNotifications/CustomNotifications.vue';
import Modal from '@/components/Modal.vue';
import TechnicalWorks from '@/components/TechnicalWorks.vue';
import RoutesMetaEnum from '@/constant/RoutesMetaEnum';
import Layout from '@/layouts/Layout.vue';
import Login from '@/pages/Login.vue';
import {
  LoadingStatesEnum,
  appLoadingState,
  loadApplication,
} from '@/store/applicationLoading';
import { isAuthorized } from '@/store/authorization';
import { info, isInfoLoaded } from '@/store/mainInfo';
import { ModalsEnum, openModal } from '@/store/modals';

const isTechnicalWorks = false;

const isLoading = computed(() => (
  appLoadingState.value === LoadingStatesEnum.LOADING
  || (
    appLoadingState.value === LoadingStatesEnum.SUCCESS
    && isAuthorized.value
    && !isInfoLoaded.value
  )
));

loadApplication();

const route = useRoute();
const { t, locale } = useI18n();
const baseTitle = document.title;

const getTitle = (pageTitle) => `${pageTitle} | ${baseTitle}`;

watch(
  () => [route.meta[RoutesMetaEnum.TITLE_KEY], isAuthorized.value, locale.value],
  ([key, isAuthed]) => {
    if (!key) {
      document.title = baseTitle;
      return;
    }

    if (!isAuthed) {
      document.title = getTitle(t('login.loginTitle'));
      return;
    }

    document.title = getTitle(t(key));
  },
);

watch(
  () => isInfoLoaded.value,
  (isLoaded) => {
    if (!isLoaded) {
      return;
    }

    if (!info.value.isConfirmAgentAgreement) {
      openModal(ModalsEnum.RULES, { closable: false });
    }
  },
  { immediate: true },
);
</script>

<style lang="scss">
@import "./styles/main.scss";
</style>
