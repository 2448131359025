import { notify } from '@kyvg/vue3-notification';
import Cookie from 'js-cookie';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { setCurrentLanguage } from '@/config/i18n';
import { checkAuthorization } from '@/store/authorization';

export const LoadingStatesEnum = {
  LOADING: Symbol('loading'),
  SUCCESS: Symbol('loading'),
  ERROR: Symbol('loading'),
};

export const appLoadingState = ref(LoadingStatesEnum.LOADING);

export const setInfoNotification = () => {
  const INFO_NOTIFICATION = 'infoNotification';

  const { t } = useI18n();

  if (Cookie.get(INFO_NOTIFICATION)) {
    return;
  }

  setTimeout(() => {
    notify({
      text: t('technicalWorks.notifications.works'),
      duration: 15000,
      data: {
        onClose() {
          Cookie.set(INFO_NOTIFICATION, true, {
            expires: 7,
          });
        },
      },
    });
  }, 500);
};

export const loadApplication = async () => {
  try {
    await setCurrentLanguage();

    checkAuthorization();
    appLoadingState.value = LoadingStatesEnum.SUCCESS;
  } catch (err) {
    appLoadingState.value = LoadingStatesEnum.ERROR;
  }
};
