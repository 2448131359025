const resource = {
  "confirmReplenishment": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to fund the account with this amount for user #", _interpolate(_named("userId")), "?"])}
  },
  "error": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "forgotPassword": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore your account, contact your personal manager or support"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])}
  },
  "paymentCompleted": {
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal rejected"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment completed successfully"])}
  },
  "replenishmentSuccess": {
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account successfully funded"])}
  }
}
export default resource