const resource = {
  "calendar": {
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "setDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set date"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])}
  },
  "tabs": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "deposits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposits"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payouts"])}
  },
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])}
}
export default resource