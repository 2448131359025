const resource = {
  "banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect deposit amount. Please contact your manager."])},
  "cash": {
    "amountExceedsLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are not enough funds on the cash register to make a deposit. Please contact your manager."])}
  },
  "forbidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect login or password"])},
  "forbiddenBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To proceed, please contact your manager"])},
  "insufficientBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are not enough funds on your balance to make a payment. Please contact your manager."])},
  "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is not found"])},
  "notNumberSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search string must be a number"])},
  "user": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player not found"])}
  },
  "withdrawal": {
    "notCorrectCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect payment code entered"])},
    "onceWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A withdrawal request has already been submitted. You will be able to submit a new request next Wednesday"])}
  }
}
export default resource